
/*slider*/
.slider_link {
display:block; padding: 5px 10px; border:1px solid #ddd;
background: #fafafa url(/style/icons/arrow_down_16.png) no-repeat center right;
}
.slider_link.opened {
background-image: url(/style/icons/arrow_up_16.png);
}

/* verwendet z.B. hier: http://www.ugb.de/seminare/themen/vollwertige-ernaehrung/ */
h4.klingelschild {
  border: 1px solid #aaa; background: #eee; color: #222;
  padding: 2px 0 2px 8px; margin: 0 0 4px; display: block;
  font-size: 9pt;
background-image: linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -o-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -moz-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -webkit-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -ms-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);

background-image: -webkit-gradient(
	linear,
	left bottom,
	left top,
	color-stop(0.01, rgb(214,214,214)),
	color-stop(1, rgb(240,240,240))
);
}



/* verwendet unter:
http://www.ugb.de/labs/startseite/
http://www.ugb.de/seminare/themen/
*/

.schilderwald h5 {
    border: 1px solid #aaa; background: #eee; color: #222;
    padding: 2px 0 2px 8px; margin: 0 0 4px; display: block;
    font-size: 9pt;
}
.schilderwald table, .schilderwald tr, .schilderwald td { margin-left: 0; }
a.klingelschild {
    border: 1px solid #aaa; background: #eee; color: #222;
    padding: 2px 0 2px 8px; margin: 0 0 4px; display: block;
}
a.klingelschild.a { padding: 8px 8px; background: #ddd }
a.klingelschild.b { font-weight: normal; font-size: 10pt; padding: 6px 8px; border-left-width: 4px; }
a.klingelschild.c { font-weight: normal; }
a.klingelschild:hover { border-color: #666; color: #000; }

.abbinder { font-size: 9pt; margin: 10px 0 0; padding: 8px; background: #eee; text-align: center; }
.abbinder a { display: inline-block; padding: 1px 10px; color: #666; font-weight: normal; }
.abbinder a:hover { color: #00f; font-weight: normal; text-decoration: underline; }


/* verlaeufe */
/* td.rightcol verwendet in /fachinfos/plaintext/ */
a.klingelschild.a {
background-image: linear-gradient(bottom, rgb(184,184,184) 1%, rgb(219,219,219) 100%);
background-image: -o-linear-gradient(bottom, rgb(184,184,184) 1%, rgb(219,219,219) 100%);
background-image: -moz-linear-gradient(bottom, rgb(184,184,184) 1%, rgb(219,219,219) 100%);
background-image: -webkit-linear-gradient(bottom, rgb(184,184,184) 1%, rgb(219,219,219) 100%);
background-image: -ms-linear-gradient(bottom, rgb(184,184,184) 1%, rgb(219,219,219) 100%);

background-image: -webkit-gradient(
	linear,
	left bottom,
	left top,
	color-stop(0.01, rgb(184,184,184)),
	color-stop(1, rgb(219,219,219))
);
}
a.klingelschild.a:hover {
background-image: linear-gradient(bottom, rgb(140,140,140) 1%, rgb(204,204,204) 100%);
background-image: -o-linear-gradient(bottom, rgb(140,140,140) 1%, rgb(204,204,204) 100%);
background-image: -moz-linear-gradient(bottom, rgb(140,140,140) 1%, rgb(204,204,204) 100%);
background-image: -webkit-linear-gradient(bottom, rgb(140,140,140) 1%, rgb(204,204,204) 100%);
background-image: -ms-linear-gradient(bottom, rgb(140,140,140) 1%, rgb(204,204,204) 100%);

background-image: -webkit-gradient(
	linear,
	left bottom,
	left top,
	color-stop(0.01, rgb(140,140,140)),
	color-stop(1, rgb(204,204,204))
);
}
a.klingelschild.b, a.klingelschild.c, td.rightcol a {
background-image: linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -o-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -moz-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -webkit-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);
background-image: -ms-linear-gradient(bottom, rgb(214,214,214) 1%, rgb(240,240,240) 100%);

background-image: -webkit-gradient(
	linear,
	left bottom,
	left top,
	color-stop(0.01, rgb(214,214,214)),
	color-stop(1, rgb(240,240,240))
);
}
a.klingelschild.b:hover, a.klingelschild.c:hover, td.rightcol a:hover {
background-image: linear-gradient(bottom, rgb(160,160,160) 1%, rgb(220,220,220) 100%);
background-image: -o-linear-gradient(bottom, rgb(160,160,160) 1%, rgb(220,220,220) 100%);
background-image: -moz-linear-gradient(bottom, rgb(160,160,160) 1%, rgb(220,220,220) 100%);
background-image: -webkit-linear-gradient(bottom, rgb(160,160,160) 1%, rgb(220,220,220) 100%);
background-image: -ms-linear-gradient(bottom, rgb(160,160,160) 1%, rgb(220,220,220) 100%);

background-image: -webkit-gradient(
	linear,
	left bottom,
	left top,
	color-stop(0.01, rgb(160,160,160)),
	color-stop(1, rgb(220,220,220))
);
}

