
/* Artikellisten (Kategorien) */

.subNodeList { margin: 0 0 0 35px; padding: 0; }
.subNodeList li { list-style-type: none; margin: 8px 0; padding: 0; }
.subNodeList img { position: absolute; margin-left: -30px; }


/* Artikellisten  */

.subArticleList div.article, .subArticleList a.article {
    margin: 10px 0 10px 0; border-top: 1px solid #ddd; padding: 10px 0 0; /*border: 1px solid #eee; background: #fafafa;*/
    display: block;
    
    &.exklusiv-1 { border: 1px solid #ccc; background: #eee url(/style/icons/page_exclusiv.png) no-repeat; background-position: right 10px top 10px; padding: 10px 10px 0 10px; }
    &.with-image div { padding: 0 0 0 150px; }
    div {  }
    img { max-width: 130px; max-height: 180px; float: left; }
    p, span.p { display: block; font-size: 10pt; line-height:13pt; }
    p.uebersicht-stichworte { color: #777; }
    p.uebersicht-stichworte a { color: #777; font-weight: normal; color: #585; }
    h2, strong.headline { display: block; background-image: none; font-size: 11pt; padding: 0; margin: 0 0 5px; }
    h2 span, strong.headline span { display: block; font-size: 8pt; color: #888; font-weight: normal; }
}

/* Startseitennews */
@include breakpoint(small down) {
.newsListBody .news img { max-width: 100%; max-height: 220px; width: auto; height: auto;  }
}
@include breakpoint(medium) {
    .newsListBody.twoCol { @include grid-row; }
    .newsListBody.twoCol .news { @include grid-column(50%); }
  .newsListBody .news img { max-width: 185px; max-height: 167px; width: auto; height: auto; float: right; padding: 0 0 10px 10px; }
 // .newsListBody .news img.image_format_h { width: 180px; } /* horizontal */
}

.newsListBody.twoCol .news img { padding: 0 0 10px 15px; }
.newsListBody.twoCol .news:nth-child(2n+1) { clear: left; }

.newsListBody a.news { color: black; font-weight: normal; }
.newsListBody a.news strong { color: $anchor-color; }
.newsListBody .news { display: block; cursor: pointer; margin: 0px 0 10px 0; padding: 10px 20px 5px 15px; }
.newsListBody .news:hover { background:#f7f7f7; box-shadow: 1px 1px 5px #444; }

@include breakpoint(medium) {
    .newsListBody div.news.with-image div { padding: 0 160px 0 0 ; }
}
.newsListBody .news div {  }
.newsListBody h2.uebersicht-header { background: transparent none; padding: 0px 0 2px 0px; margin: 0; font-size: 11pt !important; }
.newsListBody h3.uebersicht-subheader { background: transparent none; padding: 3px 0 2px 0px; margin: 0; font-weight: normal; font-size: 10pt !important; }
.newsListBody h2.uebersicht-header a {  font-size: 11pt !important; text-decoration: none; }
.newsListBody h3.uebersicht-subheader a {  font-weight: normal; font-size: 10pt !important; color: #666; text-decoration: none; }

.newsListBody .clearfix {clear:right;height:1px;display:block;}



/* Fachinfos Archiv */
.twoColImgLinks {
    @include breakpoint(small down) {
     .item img { max-width: 100%; max-height: 300px; width: auto; height: auto;  }
    }
    @include breakpoint(medium) {
      & { @include grid-row; }
      .item { @include grid-column(50%, 0); padding-left: 0; }
      .item img { width: 130px; float: right; padding: 0 0 10px 10px; }
    }

    .item img { padding: 0 0 0 15px; }
    .item:nth-child(2n+1) { clear: left; }

    a.item { color: black; font-weight: normal; }
    a.item strong { color: $anchor-color; }
    .item span { display: block; cursor: pointer; margin: 0px 10px 10px 0; padding: 8px 8px 5px 12px; box-shadow: 1px 1px 3px #aaa; border: 1px solid #ccc; }
    .item:hover span { background:#f2f2f2; box-shadow: 1px 1px 5px #444; border: 1px solid #888; }

    @include breakpoint(medium) {
        div.item.with-image div { padding: 0 160px 0 0 ; }
    }
    .item div {  }
    h2.uebersicht-header { background: transparent none; padding: 0px 0 2px 0px; margin: 0; font-size: 11pt !important; }
    h3.uebersicht-subheader { background: transparent none; padding: 3px 0 2px 0px; margin: 0; font-weight: normal; font-size: 10pt !important; }
    h2.uebersicht-header a {  font-size: 11pt !important; text-decoration: none; }
    h3.uebersicht-subheader a {  font-weight: normal; font-size: 10pt !important; color: #666; text-decoration: none; }

    .clearfix {clear:right;height:1px;display:block;}
}
