
.ci-header {
    height: 95px;
    background-color: #e8f0d9;
    // background-image: url("../images/header-background.png");
    // background-position: left top;
    // background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    
    .htext {
        position: relative;
        margin-top: 5px; margin-left: 32px;
        line-height: 1.15;
        
        .p1, .p2, .p3 { position: absolute; margin: 0; padding: 0; line-height:inherit; font-weight: bold;  }
        a { color: #54ab42; }
        .p1 { font-size: 50pt;  }
        .p2 { margin-top: 12px; margin-left: 205px; font-size: 20pt; font-weight: normal; text-transform: uppercase; }
        .p3 { margin-top: 45px; margin-left: 300px; font-size: 13pt; }
    }
    
    .hlogo {
        position:absolute;
    }
    .hlogo img { width:120px; }
    
    @include breakpoint(small only) {
        .hlogo { top: 20px; left: 14px; } .hlogo img { width: 90px; } .hlogo span {display:none}
        .htext .p1 { display: none; }
        .htext { 
            margin-left: 0; margin-top: 8px;
            .p2, .p3 { margin-left: 110px; }
            .p2 { font-size: 14pt; }
            .p3 { font-size: 10pt; margin-top: 37px; }
         }
         ul.hmenu { display: none; }
    }
    @include breakpoint(medium) {
        .hlogo { top: 20px; left: 44px; } .hlogo span {display: none}
        .htext .p1 { display: none; }
        .htext { margin-left: 0; }
    }
    @include breakpoint(large) {
       // .hlogo { top: 35px; right: 10px; }
       .hlogo { top: 10px; }
       .hlogo span {display:block;width: 140px; font-size: 75%; font-weight:normal; line-height: 1.2; padding: 2px; color: #54ab42; }
    }
    
    ul.hmenu {
        list-style-type: none;
        padding: 0 0 0 0;
        position: absolute;
        width: 500px;
        text-align: right;
        //margin-left: 550px;
        right:10px;
        margin-top: 5px;

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: inline;
            font-size: 11px;
            color: $anchor-color;
        }

        li, li a {
            text-decoration: none;
            font-weight: normal;
        }
        li::after { content: ' | '; }
        li:last-child::after { content:''; }

        a:hover{
            text-decoration:underline;
        }
        
    }
}
    
@include breakpoint(small down) {
    
}
.menu-toggle { display: block; width: 100%; margin: 0; }

