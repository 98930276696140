@charset "UTF-8";
/**
 * "Yet Another Multicolumn Layout" - (X)HTML/CSS Framework
 *
 * (en) Vertical list navigation "vlist"
 * (de) Vertikale Navigationsliste "vlist"
 *
 * @copyright       Copyright 2005-2009, Dirk Jesse
 * @license         CC-A 2.0 (http://creativecommons.org/licenses/by/2.0/),
 *                  YAML-C (http://www.yaml.de/en/license/license-conditions.html)
 * @link            http://www.yaml.de
 * @package         yaml
 * @version         3.1
 * @revision        $Revision: 343 $
 * @lastmodified    $Date: 2009-01-19 23:41:32 +0100 (Mo, 19. Jan 2009) $
 */


/* special elements above main menu */
a.newsletter { display: block; margin: 5px 0; }
a.newsletter img {
  float: left; width: 32px; height: 32px; margin: 0 3px 0 0px;
}
a.newsletter span {
  display: inline-block; margin: 5px; padding: 2px 5px; background: orange; color: white;
}


/* breadcrumbs */
ul.breadcrumbs {margin-top: 10px; margin-left: 16px;}
ul.breadcrumbs a { font-weight: normal; }


/* top menu */
nav.topnav>ul.menu {background: #54AB42;
// padding-top: 5px; 
padding-left: 10px; }
nav.topnav>ul.menu>li>a, nav.topnav>ul.menu>li>a:hover/*, nav.topnav>ul.menu>.is-active>a*/ { color: white;  }
nav.topnav>ul.menu>li.is-active>a {background:white; color: $primary-color!important;
padding-top:4px;
 }
nav.topnav>ul.menu>li.is-dropdown-submenu-parent>a::after { border-top-color: white;  }
nav.topnav>ul.menu ul li a { font-weight: normal;  }
.no-js nav.topnav>ul.menu>ul {display:none}

/* left menu */

  .vlist {
    font-weight: normal;
    /*width: 100%;*/
    overflow: hidden;
    list-style-type: none;
    margin: 0 !important;
    border-top: 1px #CCD9C5 solid;
    border-bottom: 3px #CCD9C5 solid;
    border-top: 1px #FFFFFF solid;  /* ok... */
    border-bottom: 3px #FFFFFF solid;  /* ok... */
  }

  .vlist ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .vlist li {
    margin:0;
    padding: 0;
  }

  .vlist a,
  .vlist strong,
  .vlist span {
    display:block;
    padding: 2px 0px 2px 10%;
    text-decoration: none;
    border-top: 1px #CCD9C5 solid;
    border-top: 1px #FFFFFF solid;  /* ok... */
  }

  .vlist a,
  .vlist a:visited {
    /* color: #444;  /* ok... */
  }

  .vlist li span {
    display:block;
    font-weight: bold !important;
    height: 10px; overflow: hidden;
  }

  
  
  /* Level 1 */
  .vlist li a,
  .vlist li strong,
  .vlist li span { padding-left: 10%; font-weight: bold; } /* LTR */

  /* Level 2 */
  .vlist li ul li a,
  .vlist li ul li strong,
  .vlist li ul li span { padding-left: 15%; border-top: 0; /*font-size: 11px;  /* ok... */ padding-top: 1px; padding-bottom: 1px; font-weight: normal; } /* LTR */
  
  .vlist li ul li:first-child a,
  .vlist li ul li:first-child strong,
  .vlist li ul li:first-child span { border-top: 1px #CCD9C5 solid; border-top: 1px #FFFFFF solid;  /* ok... */ } /* LTR */

  .vlist li ul li a, 
  .vlist li ul li a:visited,
  .vlist li ul li strong,
  .vlist li ul li span { background-color:#f8f8f8; color: #333; }
  
  /* Level 3 */
  .vlist li ul li ul li a,
  .vlist li ul li ul li strong,
  .vlist li ul li ul li span { padding-left: 20%; border-top: 0; } /* LTR */

  .vlist li ul li ul li a,
  .vlist li ul li ul li a:visited{ background-color:#f0f0f0; color: #222; }
  
  /* Level 4 */
  .vlist li ul li ul li ul li a,
  .vlist li ul li ul li ul li strong,
  .vlist li ul li ul li ul li span { padding-left: 25%; border-top: 0; } /* LTR */

  .vlist li ul li ul li ul li a,
  .vlist li ul li ul li ul li a:visited { background-color:#e8e8e8; color: #111; }
  
  ul.vlist li a:focus,
  ul.vlist li a:hover,
  ul.vlist li a:active { background-color:#555 !important; color: #fff !important; text-decoration: none; }
  
  ul.vlist li .private {margin-right:3px; margin-left: -19px; position:relative; top:3px;}
  
  
  /*background-color:#800!important;*/
  
  .vlist li.active a.active { font-weight: bold!important; color:white!important;background-color: #54AB42 !important; }
  .vlist li a:hover { color:#000!important;background-color: #ddd !important; }
  
  
  .vlist b.nofile { position: relative; margin-left: -19px; font: 10pt "courier new",monospace; }
  .vlist b.hassub { float: right; margin-right: 5px; font: 12pt "courier new",monospace; }
  
  .vlist li ul li a::before{ content:"›"; color: #aaa; margin: 0 6px 0 -9px; }
  .vlist li ul li a.active::before{ color: #fff; }

.accordion-menu .is-accordion-submenu a {
    font-weight: normal;
}