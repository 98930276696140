
.lah-container { 
  background: #ccc; padding: 2px 0 0 1px; margin: 0 0 15px 0;

  ul.lah-big { list-style-type: none; padding: 0; margin: 0; }
  /*ul:after { content: " "; display: block; clear: left; }*/
  ul.lah-big li { list-style-type: none; padding: 0; margin: 0; line-height: 1.4; }
  ul.lah-big li a, ul.lah-big li div {
    width: 120px; text-align: center; height: 100px; display: block; float: left; color: #000; font-weight: normal; border: 1px solid #aaa;
    background: #eee none no-repeat center 5px; padding: 5px 5px 5px; margin: 0 5px;
  }
  ul.lah-big li a:hover { background-color: #fff; border: 1px solid #333; }
  ul.lah-big li.current a, ul.lah-big li.current div {
    background-color: #dde9d1; border: 1px solid #54ab42; }
  ul.lah-big span.number { font-size: 300%; color: #666; display: block; }

  ul.lah-small { list-style-type: none; padding: 2px 5px; margin: 3px 0 0 0; background: #eee; clear: left; }
  ul.lah-small li { list-style-type: none; padding: 0; margin: 0; display: inline; }
  ul.lah-small li a { background-repeat: no-repeat; background-position: 6px 0; padding: 2px 1px 2px 26px; color: #666; font-weight: normal; text-decoration: underline; font-size: 8pt; }

  @include breakpoint(small only) {
      ul.lah-big li a, ul.lah-big li div {
          width: 79px; margin: 1px;
      }
  }
}