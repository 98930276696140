
.footer{
    //background: #4c4c4c;
    color: #636363;
    padding:10px 0 50px;
    h5 {
        color: $primary-color;
        border-top: 1px solid #cccccc;
        font-size: 12pt; font-weight: normal;
        padding: 8px 0px; margin: 30px 0 5px;
        text-transform: uppercase;
    }
    .menu>li>a { padding-left: 0; }
    a { color: #636363; text-transform: uppercase; }
    a:hover { color: $primary-color; text-decoration: underline; }
}

.footersitemap > li { float: left; width: 190px;  }
.footersitemap > li > a { color: #54ab67; }
.footersitemap li li a { font-weight: normal; text-transform: none; white-space: inherit; padding: 4px 0;}
.footersitemap li ul ul { padding-left:16px; list-style-type:disclosure-closed;}
.footersitemap li ul li ul li{ display:list-item!important;}

