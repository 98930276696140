/* disable columns for print stylesheet */
@media screen {
  .main-menu {
      background: #e8f0d9;
      @include grid-column(21%);
      
  }
  .main-content {
      @include grid-column(53%);
      .inner { padding: 0 0 0 7px; }
      .no-sidebar & {
          @include grid-column(53%+26%);
      }
      .no-menu & {
          @include grid-column(53%+21%);
      }
      .no-sidebar.no-menu & {
          @include grid-column(100%);
      }
      &, .no-sidebar & {
          @include breakpoint(medium down) {
              @include grid-column(53%+26%);
          }
      }
      .no-menu & {
          @include breakpoint(medium down) {
              @include grid-column(53%+21%);
          }
      }
      &, .no-sidebar &, .no-menu &, .no-sidebar.no-menu & {
          @include breakpoint(small down) {
              @include grid-column(100%);
          }
      }
  }

  @include breakpoint(small down) {
      .main-menu { display: none; }
  }
  .aside-content {
      @include grid-column(26%);
  }
  @include breakpoint(medium down) {
      .aside-content { @include grid-column(100%); }
  }
  .no-menu .aside-content {
    @include breakpoint(medium down) {
       @include grid-column(26%);
    }
    @include breakpoint(small down) {
       @include grid-column(100%);
    }
  }
}
