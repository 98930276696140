

img.right-or-center {
    float: right; margin: 0 0 10px 10px;
}
@include breakpoint(small down) {
    img.right-or-center {
        float: none; margin: 0 auto 10px; display: block;
    }
}


/* foto-copyrights */
.photo_copyright { color: #555; font-size: 7pt; }
.photo_copyright a { font-weight: normal; }


.fachInfoBox,.info {
  background: #f8f8f8;
  padding: 10px;
  margin-bottom: 0.5em;
  border: 1px solid #d7d7d7;
}
.info::after { clear: both; content: " "; display: block; }

.justify { text-align: justify; }
