
a.button { font-weight: normal; line-height: 1.2; }
.modern_btn a {
    @include button($style: hollow);
    font-weight: normal;  line-height: 1.2;
}
.request_info_box .modern_btn {float:left; margin-right:10px;}
.request_info_box::after { content: " "; height: 1px; display: block; clear: left; }

/* Full width checkboxes with multiline labels */
label.wide-checkbox {
  line-height:1.4; display: block; margin-left: 2rem;
}
label.wide-checkbox > input[type="checkbox"], label.wide-checkbox > input[type="radio"] {
  margin: 0 0.5rem 0 -2rem;
}


