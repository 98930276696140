

/* Lightbox Kasten */

#indexresults { border-radius: 10px; background-color: #ddd; padding: 20px; margin: 0 10px; }
#indexresults .innerDiv { border: 1px solid #333; background: white; }
#indexresults .innerDiv h2 span { float:right; }
#indexresults .innerDiv h2 span a { color: #fff; display: block; margin-top: -1px; width: 20px; height: 20px; background: #4b9539; text-align: center; }
#indexresults .innerDiv h2 span a:hover { color: #fff; background: #245c15; }
#indexresults .innerDiv h2  { background: #54ab42; color: #fff; padding: 5px 5px 5px 10px; font-size: 11pt; }
#indexresults .innerDiv div { padding: 5px 10px; min-height: 250px; }
