
.bss-slides{
  position: relative;
  display: block;
}
.bss-slides:focus{
 outline: 0;
}
.bss-slides figure{
  position: absolute;
  top: 0;
  width: 100%;
}
.bss-slides figure:first-child{
  position: relative;
}
.bss-slides figure img{
  opacity: 0;
  -webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.bss-slides .bss-show{
  z-index: 2;
}
.bss-slides .bss-show img{
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
}

.bss-slides figcaption{
  position: absolute;
  font-size: 11pt;
  left: 30px;
  top: 20px;
  padding: 15px 20px;
  color: #000;
  background: #fff;
  background: rgba(255,255,255, .85);
  opacity: 0;
  /*-webkit-transition: opacity 1.2s;
  transition: opacity 1.2s;*/
}
.bss-slides figcaption a{
  color: #000; font-weight: inherit;
}
.bss-slides figcaption h3{
    margin-bottom:5px;
}
.bss-slides figcaption h3 ,.bss-slides figcaption h3 a{
    color:#54ab42;
}
.bss-slides .bss-show figcaption{
  z-index: 3;
  opacity: 1;
}
.bss-next, .bss-prev{
  color: #fff;
  position: absolute;
  background: #000;
  background: rgba(0,0,0, .6);
  top: 50%;
  z-index: 4;
  font-size: 2em;
  margin-top: -1.2em;
  opacity: .3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bss-next:hover, .bss-prev:hover{
  cursor: pointer;
  opacity: 1;
}
.bss-next{
  right: -1px;
  padding: 10px 5px 15px 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.bss-prev{
  left: 0;
  padding: 10px 10px 15px 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.bss-fullscreen{
  display: block;
  width: 32px;
  height: 32px;
  background: rgba(0,0,0,.4) url(../img/arrows-alt_ffffff_64.png);
  -webkit-background-size: contain;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  opacity: .3;
}
.bss-fullscreen:hover{
  opacity: .8;
}
