
 .semtermine p time {display:block;color:#666;font-weight:bold; font-size:85%;}
 .semtermine p .dozenten { display:block; font-size:85%; background-image:url(/images/icons/lecturer.png); background-repeat:no-repeat; background-position: 0 0; padding: 0 0 1px 20px}
 .semtermine p .dozenten a {color: #88c; font-weight:normal;}
 .semtermine p .dozenten a:hover {color: #00f; }
 /*.semtermine p:nth-child(odd){background:#eee}*/
 .semtermine p, .semtermine h4 {margin:0;padding:8px 0; border-bottom:1px solid #ddd;}
 .semtermine .moreHover { color: #88c; font-weight: normal; }
 .semtermine .moreHover span  { display:none; }
 .semtermine .moreHover:hover  { color: blue; }
 .semtermine .moreHover:hover span  { display:block; position:absolute; background: #efefef; padding: 0; margin: -2px 0 0 100px; width: 220px; border: 1px solid #999; color: #333; }
 .semtermine .moreHover span a { display:block; font-weight: normal; padding: 5px; color: #555; }
 .semtermine .moreHover span a:nth-child(odd) { background: #f8f8f8; }
 .semtermine h4 { background: #eee; padding: 6px; color: #444;  }
 
 