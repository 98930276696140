@charset 'utf-8';

@import 'ugbhome/settings';
@import 'foundation';
@import 'motion-ui';
//@import '../../inc/socialshareprivacy/socialshareprivacy.css';

$input-font-size: rem-calc(14);
$form-label-font-size: rem-calc(12);
$form-label-line-height: inherit;

$breadcrumbs-item-separator-item: '›';
$breadcrumbs-item-uppercase: false;

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input; 
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'ci_menu';
@import 'ci_header';
@import 'grid_layout';
@import 'footer';
@import 'infobox';
@import 'typo';
@import 'forms';
@import 'ugb_headings';

@import 'ugbcomponents/keywordsearch';
@import 'ugbcomponents/article';
@import 'ugbcomponents/klingelschilder';
@import 'ugbcomponents/articlelists';
@import 'ugbcomponents/checkout';
@import 'ugbcomponents/homeneu';
@import 'ugbcomponents/newsletter';
@import 'ugbcomponents/search';
@import 'ugbcomponents/semtermine';
@import 'print';

/* table with special class */
table.table {
    @include table($nest: false);
}

table.table.stack {
    @include breakpoint(medium down) {
      @include table-stack;
    }
}

table.table.scroll {
    @include table-scroll;
}

table.table.hover {
    @include table-hover;
}

.table-scroll {
    overflow-x: auto;

    table {
      width: auto;
    }
}

