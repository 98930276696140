

/* spezialformate in fachartikeln */

.abstract  { font-size:14px; font-weight:bold; line-height : 1.5; }


/* dinge unter einem fachartikel */
#keyword_related .subArticleList div.article h2 { font-size: 9pt; }
.article_footer h4 { padding: 5px 0; font-size: 11pt; font-weight: normal; margin: 3px 0; }
.article_pdf_download {
	_border-top: 1px solid #ddd; color: #666; margin: 10px 0 0; padding: 10px 5px; text-align: right;
}

/* Stichwortliste bei Fachinfos */
.fachartikel-stichworte { margin: 15px 0 10px; color: #555; }
.fachartikel-stichworte a { color: #585 !important; font-weight: normal !important; }
.fachartikel-stichworte a:hover { color: #54ab42 !important;color: #00f !important; font-weight: normal; text-decoration: underline; }

.fachartikel-stichworte h4 { display: block; margin-bottom: 5px; padding: 0; font-size: 10pt; font-weight: normal; }
.fachartikel-stichworte.related { _padding-left: 80px; _background: white url(/style/icons/article-related2.png) no-repeat 10px 2px; }
.fachartikel-stichworte.tags { _padding-left: 80px; _background: white url(/style/icons/flag-green.png) no-repeat 10px 2px; }
.fachartikel-stichworte { padding-left: 5px; }
.fachartikel-stichworte.tags a { display: block; float: left; padding: 2px 4px; }

.fachartikel-stichworte a.no_articles { color: #555 !important; }



.sliderbox-header {
   border: 1px solid #ccc;
  background: #f0f0f0; margin: 10px 6px;
}
.sliderbox-header header {
  cursor: pointer; padding: 5px 10px;
}
.sliderbox-header header:hover {
  background: #ddd;
}
.sliderbox-header .expand {
  display: none; font-size: 8pt; padding: 5px 10px;
}
.sliderbox-header .expand td { font-size: 8pt !important; padding: 0; }
.sliderbox-header.small h4{
  display:table-cell; width: 110px; color: #555; color: green;
}
.sliderbox-header.small span{
  font-size: 9pt;
  padding: 0 5px; display:table-cell;
}
.sliderbox-header h4{
  padding: 5px 0;  font-size: 11pt; margin: 0;
}
.sliderbox-header h4 span{
  font-size: 9pt;
}
.sliderbox-header h4 small{
  font-size: 9pt;
}


