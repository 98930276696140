
/* Formatierung Infoboxen neu! */



.infobox_sidebar {
  display: block; border: 1px solid #54AB42;
  background-color: #DDE9D1;
	padding-bottom:10px;
	margin-bottom:20px;
  font-size: 12px;
  /*border-radius: 10px 10px 0  0;*/ overflow: hidden;
}
.infobox_sidebar a { font-weight: inherit!important; }
.infobox_sidebar h4 {
	padding-left: 5px;
  padding: 3px 0 4px 20px;
  margin: 0 0 0 0;
  background: #54ab42 url(/style/pfeil_weiss.png) no-repeat 8px 7px;
  font-size: 14px;
}
.infobox_sidebar h4, .infobox_sidebar h4 a {
  color: #FFFFFF;
  font-weight: bold;
}
.infobox_sidebar div.innerDiv {
  padding: 6px;
  font-weight: normal !important;
}
.infobox_sidebar.infobox_horiz {
  padding-bottom: 0;
}
.infobox_sidebar.infobox_horiz div.innerDiv { padding: 6px 6px 0; }
.infobox_sidebar.infobox_horiz img { float: left; }
/* eindrittel Bild zweidrittel Text */
.infobox_sidebar.infobox_horiz.third div.innerDiv {margin-left: 40%;}
.infobox_sidebar.infobox_horiz.third img {width: 36%;}
/* halb Bild halb Text */
.infobox_sidebar.infobox_horiz.half div.innerDiv {margin-left: 50%;}
.infobox_sidebar.infobox_horiz.half img {width: 48%;}

.infobox_sidebar.infobox_vert img { width: 100%; }

.infobox_probeabo img { float: left; width: 90px; border-right: 1px solid #54ab42; }
.infobox_probeabo div.innerDiv { padding-top: 18px; margin-left: 90px; text-align: center; font-size: 10pt; color: #B3404C; }

.infobox_partner { border: 0; background: transparent; }
.infobox_partner h4 { border: 0; border-bottom: 1px solid #54ab42; padding: 0 0 3px 4px; background: transparent; color: #54ab42; font-size: 12px; font-weight: normal; }
.infobox_partner div.innerDiv { padding: 5px 0 0; }
.infobox_partner div.innerDiv img { margin: 5px 0; box-shadow: 2px 2px 4px #444; -moz-box-shadow: 2px 2px 4px #444; -o-box-shadow: 2px 2px 4px #444; -webkit-box-shadow: 2px 2px 4px #444; }
.infobox_partner div.innerDiv img:hover { box-shadow: 2px 2px 8px #000; }

.infobox_sidebar.infobox_imageonly, .infobox_sidebar.infobox_imageonly div.innerDiv { background: transparent; border: 0; padding: 0; }
.infobox_sidebar.infobox_imageonly  h4 a { color: #555 }
.infobox_sidebar.infobox_imageonly  img { box-shadow: 3px 3px 3px #999999; margin: 0 4px 4px 0; }
.infobox_sidebar.infobox_imageonly a:hover img { box-shadow: 3px 3px 3px #666666; }
.infobox_sidebar.infobox_imageonly a:active img { box-shadow: 0 0; margin: 4px 0 0 4px; }

.infobox_sidebar.white { background: transparent; border: none; }
.infobox_sidebar.white .innerDiv { padding-left: 0; padding-right: 0; color: #333}
.infobox_sidebar.white div.innerDiv>a { color: #333; }

ul.pfeile {
  margin: 0 0 0 20px; padding: 0 0 0 0;
}
ul.pfeile li {
  list-style-image: url(/style/icons/pfeil_gruen2.png);
  float:none!important;
  margin: 0 0 0 0; padding: 0 0 0 0;
}

.infobox_share {
	background-color: white;
	border: 0 none;
	margin: 5px 0 10px 0;
	padding: 0;
}
.infobox_share h4, .infobox_imageonly h4 {
	padding: 0 0 2px 0;
	margin: 0 0 5px 0;
	border: 0; border-bottom: 1px solid #eee; background: transparent;
	color: #555;
	font-size: 10px;
}

.infobox_sidebar.placeholder, .infobox_sidebar.placeholder h4, .infobox_sidebar.placeholder h4 a, .infobox_sidebar.placeholder .innerDiv {
    border: 0; background: transparent; padding: 0; color: black;
}


div.infobox_tagcloud_secondary {
  background-color: #eee; text-align: center; border: 0;
}
div.infobox_tagcloud_secondary a {
  color: #333
}
div.infobox_tagcloud_secondary h4 { border-bottom: 1px solid #fff; background: #ccc; font-size: 10pt; padding: 5px; margin: 0 0 10px; color: #333; }
div.infobox_tagcloud a.tag.i-0 { font-size: 7.5pt; }
div.infobox_tagcloud a.tag.i-1 { font-size: 10pt; }
div.infobox_tagcloud a.tag.i-2 { font-size: 12pt; }
div.infobox_tagcloud a.tag.i-3 { font-size: 13pt; }
div.infobox_tagcloud a.tag.c-0 { color: #999; }
div.infobox_tagcloud a.tag.c-1 { color: #ccc; }
div.infobox_tagcloud a.tag.c-2 { color: #fff; }
div.infobox_tagcloud a.tag.c-3 { color: #fff; background: #555; padding: 0 3px; }

/* use with infobox_white or infobox_imghead */
.infobox_sidebar.termine p time { display: block; font-size: 90%; color: #333; }

/* use with infobox_white */
.infobox_sidebar.imghead { background:white; border: none; margin-top: 35px; }
.infobox_sidebar.imghead h4 { font-size: 19pt; font-weight: normal; background: white; padding-left: 5px; padding-bottom: 5px; }
.infobox_sidebar.imghead h4, .infobox_sidebar.imghead h4 a{  font-weight: normal; color: #555;  }

.infobox_sidebar.imghead h4 span { margin-left: 10px; padding-bottom: 3px; border-bottom: 2px solid #999; }

/* ENDE Infoboxen */

/*infoboxen*/
div.infobox_tagcloud {
  background-color: #222; text-align: center; border: 0;
}
div.infobox_tagcloud a.tag {
  color: #fff
}
div.infobox_tagcloud h4 {
  display: none;
}
div.infobox_tagcloud a.tag.i-0 { font-size: 7.5pt; }
div.infobox_tagcloud a.tag.i-1 { font-size: 10pt; }
div.infobox_tagcloud a.tag.i-2 { font-size: 12pt; }
div.infobox_tagcloud a.tag.i-3 { font-size: 13pt; }
div.infobox_tagcloud a.tag.c-0 { color: #999; }
div.infobox_tagcloud a.tag.c-1 { color: #ccc; }
div.infobox_tagcloud a.tag.c-2 { color: #fff; }
div.infobox_tagcloud a.tag.c-3 { color: #fff; background: #555; padding: 0 3px; }


div.infobox_sidebar.headeronly div.innerDiv { padding: 0; }
div.infobox_sidebar.headeronly { padding: 0; }

div.infobox_sidebar.contentonly h4 { display: none }
div.infobox_sidebar.contentonly { padding-bottom: 0; text-align: center; }





/* Infoboxen im Contentbereich */

.infobox_490 {
  border: 1px solid #66b355;
  padding: 6px 10px;
  display:block;
  font-size:8pt;
  color:#66b355;
  width:160px;
  line-height:1.5em;
  margin: 0 0 10px 10px;
  float: right;
}
/*.infobox_400 {
  border-bottom: 2px solid #ffbf3b; color: #666; margin: 0 0 0; padding: 6px 5px 10px; text-align: center; background: #ffa;
}*/
.infobox_400 {
  border-bottom: 1px solid #ddd; color: #666; margin: 0 0 0; padding: 6px 5px 10px 80px;
  
}
.infobox_400 h4 {
  margin: 0px 0 5px; padding: 0; font-size: 10pt; color: #555;
}


/* Infoboxen im Text */

.infobox_left_intext {
  float: left; width: 44%;  padding: 5px 8px 5px 8px; margin: 5px 10px 5px 0;
}
.infobox_left_intext, .infobox_small {
  font-size: 11px; color: #555;
  border-top: 1px solid #bbb; border-bottom: 1px solid #bbb; /*text-align: justify;*/
  background: #fff; line-height: 1.6;
}

.infobox_tagung_gross {
	background: transparent; border: 0; padding: 10px 0 0;
}
.infobox_tagung_gross div.innerDiv { padding: 0; }
.infobox_tagung_gross h4 {
	display: none;
}
.infobox_tagung_gross ul.themen  { padding-left:1px; }

/* ENDE Infoboxen */

