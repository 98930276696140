
/* tags_popular */

#tags_popular { margin: 0 ; }
#tags_popular .head {
	padding: 0 0 2px 5px;	margin: 0 0 2px 0;
	border: 1px solid #f5f5f5; background: #fafafa;
	color: #555; font-size: 9px; cursor: pointer;
}
#tags_popular .head.active {
	border: 1px solid #eaeaea; background: #f1f1f1;	color: #555;
}
#tags_popular .cont {
	padding: 0 0 2px 0; margin: 0 0 5px 0; width: 234px;
	border: 1px solid #eee; background: transparent;
	color: #555; background: #fafafa; position: absolute;
	font-size: 10px; max-height: 150px; overflow: auto;
}
#tags_popular .cont ul {
	margin: 0; padding: 0;
}
#tags_popular .cont .item {
	display: block; list-style-type: none; padding: 3px 0 3px 5px; margin: 0 0 0 0; cursor: pointer; line-height:1.2; font-size: 10px; font-weight: normal;
}
#tags_popular .cont h3:hover, #tags_popular .cont span:hover, #tags_popular .cont li:hover {
	background: #666; color: #fff;
}
#tags_popular .cont .active {
	background: #54ab42; color: #fff;
}

/* Dropdown Suchbox */
#globsearchdd { 
    display: none; position: absolute; margin-left: 0px; margin-top: -16px; width: 185px; border: 1px solid #54ab42; border-top: 0 none; background: white; max-height: 350px; overflow: auto;
    a { font-weight: normal; display: block; padding: 2px 4px; border: 1px solid transparent; }
    a:hover, a:active { background: #8bc27a; color: white; border-color: #54ab42; }
    a.selected { background: #6380c2; color: white; border-color: #424cab; }
}
